<template>
    <div class="wrapper">
        <van-nav-bar title="用户注册" @click-left="goHome">
            <template #left>
                <van-icon name="wap-home" size="18"/>
            </template>
            <template #right>
                <a @click="goHelpCenter">
                    <van-icon name="question-o" size="18"/>
                </a>
            </template>
        </van-nav-bar>
        <div class="register">
            <div class="headline">
                <h3>V聘360注册</h3>
            </div>
            <div class="register_form">
                <van-form>
                    <div class="register_input">
                        <van-field name="手机号" v-model.trim="registerForm.phone" label="手机号" placeholder="请填写"
                                   :rules="[{ validator: validatePhone, trigger: 'onBlur' }]" required clearable/>

                        <van-field name="验证码" v-model.trim="registerForm.authCode" label="验证码" placeholder="请填写"
                                   :rules="[{ required: true, message: '请填写验证码', trigger: 'onSubmit' }]" required clearable>
                            <template v-if="disabled" #button>
                                <van-button size="small" type="default">剩余 {{ remainingTime }} 秒获取验证码</van-button>
                            </template>
                            <template v-else #button>
                                <van-button size="small" :disabled="clickDisabled" native-type="button" @click="handleAuthCode">获取验证码
                                </van-button>
                            </template>
                        </van-field>

                        <van-field class="two_words" v-model.trim="registerForm.password" type="password" name="密码" label="密码" placeholder="请填写" :rules="[{ required: true, message: '请填写密码', trigger: 'onBlur' }]" required clearable />

                        <van-field class="two_words" v-model.trim="registerForm.email" name="邮箱" label="邮箱" placeholder="请填写" :rules="[{message: '请填写邮箱', trigger: 'onBlur' }]" clearable />

                    </div>
                    <div class="checkbox">
                        <van-checkbox v-model="checked" shape="square"/>
                        我已阅读并同意<a class="inline_link" @click="goTermOfService">服务条款</a>、<a @click="goPrivacyPolicy" class="inline_link">隐私政策</a>
                    </div>
                    <div class="submit_btn">
                        <van-button type="primary" native-type="submit" @click="handleSubmit" block>注册</van-button>
                    </div>
                    <div class="register_btn">
                        已有账号？<a class="inline_link" @click="goLogin">立即登录</a>
                    </div>
                    <van-dialog v-model="showTermOfService" title="服务条款">
                        <div class="section">
                            <h3>欢迎您注册成为V聘360用户！</h3>
                            <p>
                                《V聘360用户服务协议》（以下简称“本协议”）是由同道精英(天津)信息技术有限公司
                                （以下简称“V聘360”或者“平台”）和您签订。
                            </p>
                            <h4>第一条 协议确认</h4>
                            <p>
                                1. 您在注册、登陆使用V聘360提供的各项服务之前，请您仔细阅读并理解本协议全部内
                                容，做出您认为适当的选择。平台努力用通俗易懂、简单明了的文字表达，同时对于在本协
                                议中与您的权益存在重大关系的条款，采用粗体字进行标注以提示您注意。当您点击“注册”
                                或者“登录”或其他方式确认即表示您已经仔细阅读并完全理解、同意本协议项下的全部
                                条款。
                            </p>
                            <p>
                                2. 如果平台对本协议进行修改，平台将在V聘360网站（www.vpin360.com、www.vpin360.cn）
                                提前予以公布，更变公示期（7日）届满时生效。若您不同意修改后的协议，您有权停止使
                                用V聘360服务或者注销您的V聘360账户；双方协商一致的，也可另行变更协议内容。您如果继
                                续“登录”或其他方式确认、使用V聘360提供的服务，即表示已经仔细阅读并完全理解、同
                                意修改后的协议。
                            </p>
                            <h4>第二条 账户的注册、使用、注销</h4>
                            <p>
                                1. 在您开始注册使用V聘360服务前，您应当具备中华人民共和国法律规定的与您的行为
                                相适应的民事行为能力。
                            </p>
                            <p>
                                2. 您同意本协议的全部条款并按照登陆页面上的提示提供您本人真实、准确、有效信
                                息并完成全部注册程序后，才能成为V聘360用户。
                            </p>
                            <p>
                                2.1 如果您是求职用户，建议您尽量完善和公开您的简历信息以更好进行求职。您同意
                                V聘360或在您选择公开简历范围内的V聘360用户可以免费/付费查看或获取您填写或上传的简
                                历信息。
                            </p>
                            <p>
                                2.2 如果您是招聘用户，您需要完成实名认证、实人认证并关联用人单位，保证您在
                                V聘360平台的相关行为均为职务行为后，才能使用V聘360提供的招聘服务。为了平台的安全运行，
                                招聘信息的真实、有效，平台视情况定期或不定期核查已经通过认证的招聘用户，以验证认
                                证信息的真实有效性。如在核查过程中发现招聘用户提供的信息不符合规定或虚假或无效
                                的，平台有权对违规信息及该用户、关联单位采取包括但不限于删除/屏蔽/修改违规信息、
                                扣除资源、限制登录、暂停使用、终止合作、永久封禁账户等平台管制措施。
                            </p>
                            <p>
                                3. 您应妥善保证自己的账号和密码，并对您账户进行的所有活动和事件承担法律责任。
                                如您是企业用户，您对认证后关联的个人用户的所有行为承担连带责任。
                            </p>
                            <p>
                                4. 您在使用V聘360网络存储平台和/或享受V聘360提供的服务过程中，应当遵守国家法律
                                法规以及所有与V聘360服务有关的网络协议、规定、产品规则或程序，不得有下列任何行为，
                                否则V聘360有权不通知您自行采取措施，包括但不限于删除/屏蔽/修改用户发布的内容，限
                                制、暂停用户使用，取消订单，或终止合作、永久封禁账户等措施：
                            </p>
                            <p>
                                4.1 提供虚假信息注册的（包括但不限于冒充其他人、单位）或者仿冒、混淆他人账
                                号对外展示名称、头像或者发布让人容易产生混淆、误解的内容的行为；
                            </p>
                            <p>4.2 在平台上骚扰、辱骂、歧视、威胁他人或存在其他不正当行为；</p>
                            <p>
                                4.3 通过任何技术手段侵入平台的软件、网站、数据库等系统，非法抓取平台数据或
                                者干扰V聘360产品和/或服务正常运行的。非法抓取是指采用程序或者非正常浏览等技术手段
                                获取内容数据的行为，包括但不限于采用“蜘蛛”(spider)程序、爬虫程序、拟人程序等
                                非真实用户或避开、破坏技术措施等非正常浏览的手段、方式，读取、复制、转存、获得
                                数据和信息内容的行为；
                            </p>
                            <p>
                                4.4 任何利用自有或第三方的插件或程序盗链、抓取、读取、模拟下载、深度链接、
                                收集平台展示的任何信息的；
                            </p>
                            <p>
                                4.5 侵犯V聘360或第三方的专利权、著作权、商标权、名誉权或其他任何合法权益的行
                                为；
                            </p>
                            <p>4.6 未经V聘360许可，在平台发布或利用平台内任何信息发布广告或实施营销行为；</p>
                            <p>
                                4.7 利用平台发布、传输任何新闻信息，包括但不限于有关政治、经济、军事、外交
                                等社会公共事务的报道、评论，以及有关社会突发事件的报道、评论；
                            </p>
                            <p>
                                4.8 未按照V聘360产品及服务声明的目的、方式使用V聘360产品、数据（含平台用户个人
                                信息）及服务，或利用V聘360产品、数据（含平台用户个人信息）及服务实施与V聘360产品及
                                服务目的、方式无关的行为。
                            </p>
                            <p>
                                4.9 其他违反国家法律法规以及所有与V聘360服务有关的网络协议、规定、产品规则或
                                程序的行为。
                            </p>
                            <p>5. 招聘用户还需遵守V聘360公布的《信用分规则》、《职位发布规则》等线上协议。</p>
                            <p>
                                6. 您购买或者V聘360为您提供、赠送的线上服务和/或资源均有有效期，有效期结束后
                                线上服务和/或资源自动清零、服务权限降低，资源有效期不可中断或延期，V聘360其他服务
                                条款或优惠活动另有规定的除外。如您为招聘用户，在您发布职位信息时应当遵守职位信
                                息有效期约定，V聘360将帮助您下线有效期届满但仍未下线的职位信息。
                            </p>
                            <p>
                                7.您理解，基于网络服务平台特性，V聘360无法保证某一招聘信息或简历会有一定数量的
                                用户来浏览，也无法确保会有一位特定的用户来浏览。
                            </p>
                            <p>
                                8. 您知悉并同意在拨打V聘360电话时均可能会被录音，V聘360电话包括但不限于客服电
                                话、招聘/求职过程通话、销售电话等。
                            </p>
                            <p>
                                9. V聘360有权拒绝为与本单位经营同类业务、有业务竞争关系或者其他利害关系的单位
                                及个人提供服务。
                            </p>
                            <p>
                                10. 如需要注销的V聘360账户，您可以在V聘360 APP 上申请注销您的V聘360账户。在注销账户
                                之后，平台将停止为您提供所有的产品和服务，并依据您的要求，删除您的个人信息，但法
                                律法规另有规定的除外。
                            </p>
                        </div>
                    </van-dialog>
                    <van-dialog v-model="showPrivacyPolicy" title="隐私政策">
                        <div class="section">
                            <h3>欢迎您注册成为V聘360用户！</h3>
                            <p>
                                《V聘360用户服务协议》（以下简称“本协议”）是由同道精英(天津)信息技术有限公司
                                （以下简称“V聘360”或者“平台”）和您签订。
                            </p>
                            <h4>第一条 协议确认</h4>
                            <p>
                                1. 您在注册、登陆使用V聘360提供的各项服务之前，请您仔细阅读并理解本协议全部内
                                容，做出您认为适当的选择。平台努力用通俗易懂、简单明了的文字表达，同时对于在本协
                                议中与您的权益存在重大关系的条款，采用粗体字进行标注以提示您注意。当您点击“注册”
                                或者“登录”或其他方式确认即表示您已经仔细阅读并完全理解、同意本协议项下的全部
                                条款。
                            </p>
                            <p>
                                2. 如果平台对本协议进行修改，平台将在V聘360网站（www.vpin360.com、www.vpin360.cn）
                                提前予以公布，更变公示期（7 日）届满时生效。若您不同意修改后的协议，您有权停止使
                                用V聘360服务或者注销您的V聘360账户；双方协商一致的，也可另行变更协议内容。您如果继
                                续“登录”或其他方式确认、使用V聘360提供的服务，即表示已经仔细阅读并完全理解、同
                                意修改后的协议。
                            </p>
                            <h4>第二条 账户的注册、使用、注销</h4>
                            <p>
                                1. 在您开始注册使用V聘360服务前，您应当具备中华人民共和国法律规定的与您的行为
                                相适应的民事行为能力。
                            </p>
                            <p>
                                2. 您同意本协议的全部条款并按照登陆页面上的提示提供您本人真实、准确、有效信
                                息并完成全部注册程序后，才能成为V聘360用户。
                            </p>
                            <p>
                                2.1 如果您是求职用户，建议您尽量完善和公开您的简历信息以更好进行求职。您同意
                                V聘360或在您选择公开简历范围内的V聘360用户可以免费/付费查看或获取您填写或上传的简
                                历信息。
                            </p>
                            <p>
                                2.2 如果您是招聘用户，您需要完成实名认证、实人认证并关联用人单位，保证您在
                                V聘360平台的相关行为均为职务行为后，才能使用V聘360提供的招聘服务。为了平台的安全运行，
                                招聘信息的真实、有效，平台视情况定期或不定期核查已经通过认证的招聘用户，以验证认
                                证信息的真实有效性。如在核查过程中发现招聘用户提供的信息不符合规定或虚假或无效
                                的，平台有权对违规信息及该用户、关联单位采取包括但不限于删除/屏蔽/修改违规信息、
                                扣除资源、限制登录、暂停使用、终止合作、永久封禁账户等平台管制措施。
                            </p>
                            <p>
                                3. 您应妥善保证自己的账号和密码，并对您账户进行的所有活动和事件承担法律责任。
                                如您是企业用户，您对认证后关联的个人用户的所有行为承担连带责任。
                            </p>
                            <p>
                                4. 您在使用V聘360网络存储平台和/或享受V聘360提供的服务过程中，应当遵守国家法律
                                法规以及所有与V聘360服务有关的网络协议、规定、产品规则或程序，不得有下列任何行为，
                                否则V聘360有权不通知您自行采取措施，包括但不限于删除/屏蔽/修改用户发布的内容，限
                                制、暂停用户使用，取消订单，或终止合作、永久封禁账户等措施：
                            </p>
                            <p>
                                4.1 提供虚假信息注册的（包括但不限于冒充其他人、单位）或者仿冒、混淆他人账
                                号对外展示名称、头像或者发布让人容易产生混淆、误解的内容的行为；
                            </p>
                            <p>4.2 在平台上骚扰、辱骂、歧视、威胁他人或存在其他不正当行为；</p>
                            <p>
                                4.3 通过任何技术手段侵入平台的软件、网站、数据库等系统，非法抓取平台数据或
                                者干扰V聘360产品和/或服务正常运行的。非法抓取是指采用程序或者非正常浏览等技术手段
                                获取内容数据的行为，包括但不限于采用“蜘蛛”(spider)程序、爬虫程序、拟人程序等
                                非真实用户或避开、破坏技术措施等非正常浏览的手段、方式，读取、复制、转存、获得
                                数据和信息内容的行为；
                            </p>
                            <p>
                                4.4 任何利用自有或第三方的插件或程序盗链、抓取、读取、模拟下载、深度链接、
                                收集平台展示的任何信息的；
                            </p>
                            <p>
                                4.5 侵犯V聘360或第三方的专利权、著作权、商标权、名誉权或其他任何合法权益的行
                                为；
                            </p>
                            <p>4.6 未经V聘360许可，在平台发布或利用平台内任何信息发布广告或实施营销行为；</p>
                            <p>
                                4.7 利用平台发布、传输任何新闻信息，包括但不限于有关政治、经济、军事、外交
                                等社会公共事务的报道、评论，以及有关社会突发事件的报道、评论；
                            </p>
                            <p>
                                4.8 未按照V聘360产品及服务声明的目的、方式使用V聘360产品、数据（含平台用户个人
                                信息）及服务，或利用V聘360产品、数据（含平台用户个人信息）及服务实施与V聘360产品及
                                服务目的、方式无关的行为。
                            </p>
                            <p>
                                4.9 其他违反国家法律法规以及所有与V聘360服务有关的网络协议、规定、产品规则或
                                程序的行为。
                            </p>
                            <p>5. 招聘用户还需遵守V聘360公布的《信用分规则》、《职位发布规则》等线上协议。</p>
                            <p>
                                6. 您购买或者V聘360为您提供、赠送的线上服务和/或资源均有有效期，有效期结束后
                                线上服务和/或资源自动清零、服务权限降低，资源有效期不可中断或延期，V聘360其他服务
                                条款或优惠活动另有规定的除外。如您为招聘用户，在您发布职位信息时应当遵守职位信
                                息有效期约定，V聘360将帮助您下线有效期届满但仍未下线的职位信息。
                            </p>
                            <p>
                                7.您理解，基于网络服务平台特性，V聘360无法保证某一招聘信息或简历会有一定数量的
                                用户来浏览，也无法确保会有一位特定的用户来浏览。
                            </p>
                            <p>
                                8. 您知悉并同意在拨打V聘360电话时均可能会被录音，V聘360电话包括但不限于客服电
                                话、招聘/求职过程通话、销售电话等。
                            </p>
                            <p>
                                9. V聘360有权拒绝为与本单位经营同类业务、有业务竞争关系或者其他利害关系的单位
                                及个人提供服务。
                            </p>
                            <p>
                                10. 如需要注销的V聘360账户，您可以在V聘360 APP 上申请注销您的V聘360账户。在注销账户
                                之后，平台将停止为您提供所有的产品和服务，并依据您的要求，删除您的个人信息，但法
                                律法规另有规定的除外。
                            </p>
                        </div>
                    </van-dialog>
                </van-form>
            </div>
            <div class="footer">
                <div class="copyright">Copyright &copy; {{ (new Date).getFullYear() }} 节点速聘（西安）人力资源服务有限公司</div>
            </div>
        </div>
    </div>
</template>

<script>

import {phoneIsUsed, reqRegisterApplicant, reqSendAuthCode} from "../../api/client-api";
import {checkMobile, encode64} from "../../assets/js/utils";

export default {
    data() {
        return {
            showPrivacyPolicy: false,
            showTermOfService: false,
            loading: false,
            disabled: false,
            clickDisabled: true,
            checked: false,
            projectCode: null,
            remainingTime: 10,
            registerForm: {
                phone: '',
                authCode: '',
                password: '',
                email: '',
                sign: false,
            },
        }
    },
    methods: {
        goTermOfService() {
            this.showTermOfService = true;
        },
        goPrivacyPolicy() {
            this.showPrivacyPolicy = true;
        },
        goHome() {
            window.history.back(-1)
        },
        goHelpCenter() {
            this.$router.push({name: 'helpCenter', query: {projectCode: this.projectCode}});
        },
        goLogin() {
            this.$router.push({name: 'login', query: {projectCode: this.projectCode}});
        },
        validatePhone(value, rule) {
            this.clickDisabled = false
            if (!value) {
                rule.message = '请输入手机号';
                this.clickDisabled = true;
                return false;
            }
            if (!checkMobile(value)) {
                rule.message = '手机号不正确';
                this.clickDisabled = true;
                return false;
            }

            return new Promise((resolve) => {
                phoneIsUsed({phone: value}).then((res)=>{
                    rule.message = '手机号码已注册';
                    this.clickDisabled = res.data.data;
                    resolve(!res.data.data);
                }).catch((err)=>{
                    rule.message = '手机号码校验失败';
                    resolve(false);
                })
            });
        },
        handleAuthCode() {
            if (this.disabled) {
                return;
            }

            if (!this.registerForm.phone) {
                this.$notify({type: 'danger', duration: '1000', message: '请输入手机号码'});
                return;
            }

            reqSendAuthCode({phone: this.registerForm.phone}).then((res) => {
                this.remainingTime = 0;
                if (res.data.code === 1) {
                    this.remainingTime = res.data.data * 60;
                    this.disabled = true;
                    const run = setInterval(() => {
                        if (this.remainingTime === 0) {
                            clearInterval(run);
                            this.disabled = false;
                        }
                        this.remainingTime--;
                    }, 1000);
                }
            }).catch((err) => {
                this.remainingTime = 0;
                this.disabled = false;
                console.log('err===', err);
            })
        },
        handleSubmit() {
            if (this.checked === true) {
                this.loading = true;
                this.registerForm.password = encode64(encode64(this.registerForm.password) + 'pwd');
                reqRegisterApplicant(this.registerForm).then((res) => {
                    this.loading = false;
                    if (res.data.data === 1) {
                        this.$notify({type: 'success', duration: '1000', message: '恭喜你注册成功'});
                        this.$router.push({name: 'login', query: {projectCode: this.projectCode}});
                    } else if (res.data.data === 2 || res.data.data === 4) {
                        this.$notify({type: 'danger', duration: '1000', message: '验证码不正确'});
                        this.registerForm.password = ''
                    } else if (res.data.data === 3) {
                        this.$notify({type: 'danger', duration: '1000', message: '用户已存在'});
                        this.registerForm.password = ''
                    } else {
                        this.$notify({type: 'danger', duration: '1000', message: '注册失败'});
                        this.registerForm.password = ''
                    }
                }).catch((err) => {
                    this.loading = false;
                    this.$Message.error('注册失败');
                    this.registerForm.password = ''
                })
            } else {
                this.$notify({type: 'danger', duration: '1000', message: '请检查是否已阅读并同意服务条款、隐私政策'});
            }
        },
    },
    mounted() {
        this.projectCode = this.$route.query.projectCode;
    }
}
</script>

<style scoped>


</style>
