<template>
    <div class="wrapper">
        <div class="header">
            <Row>
                <Col span="18" class="title">
                    <Icon type="md-home" />V聘360
                </Col>
                <Col span="6" class="menu">
                    <a @click="handleLogin">登录</a> ｜ <a @click="goHelpCenter">帮助</a>
                </Col>
            </Row>
        </div>
        <div class="banner"><img src="../../assets/img/default-banner.png" alt=""></div>
        <div class="content">
            <div class="main-content">
                <div class="reg-steps">
                    <div class="headline">用户注册</div>
                    <div class="steps">
                        <Steps :current="currentStep">
                            <Step title="基本信息"></Step>
                            <Step title="注册完成"></Step>
                        </Steps>
                    </div>
                    <div class="step-1" v-if="currentStep === 0">
                        <Form ref="registerForm" :model="registerForm" :rules="registerFormRule" label-colon :label-width="70">
                            <FormItem label="手机号" prop="phone">
                                <Input size="large" type="text" placeholder="请输入" v-model.trim="registerForm.phone"/>
                            </FormItem>
                            <FormItem label="验证码" prop="authCode">
                                <Input size="large" type="text" placeholder="请输入" v-model.trim="registerForm.authCode">
                                    <Button type="primary" slot="append" v-if="disabled">剩余 {{remainingTime}} 秒</Button>
                                    <Button type="primary" slot="append" :disabled="clickDisabled" v-else @click="handleAuthCode">获取验证码</Button>
                                </Input>
                            </FormItem>
                            <FormItem prop="password">
                                <div class="text-spacing" slot="label">密&#160;&#160;&#160;&#160;码:</div>
                                <Input size="large" type="password" placeholder="请输入" v-model.trim="registerForm.password"/>
                            </FormItem>
                            <FormItem prop="email">
                                <div class="text-spacing" slot="label">邮&#160;&#160;&#160;&#160;箱:</div>
                                <Input size="large" type="text" placeholder="请输入" v-model.trim="registerForm.email"/>
                            </FormItem>
                            <FormItem prop="sign">
                                <Checkbox v-model="registerForm.sign"></Checkbox>
                                我已阅读并同意<a @click="goTermOfService">服务条款</a>、<a @click="goPrivacyPolicy">隐私政策</a>
                            </FormItem>
                            <FormItem>
                                <div class="submit-btn">
                                    <Button size="large" type="primary" shape="circle" long @click="handleSubmit" :loading="loading">下一步</Button>
                                </div>
                            </FormItem>
                        </Form>
                    </div>

                    <div class="step-2" v-if="currentStep === 1">
                        <div class="reg-result">
                            <div class="reg-success-icon"><i class="ivu-icon ivu-icon-ios-checkmark-circle"></i></div>
                            <div class="reg-success-text">恭喜您，注册成功！</div>
                        </div>
                        <div class="quick-link">
                            <a @click="handleLogin">立即登录</a> 或
                            <a @click="goProjectHome">返回首页</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
        <Login v-model="showLoginModal" :goHome="true" v-on:goProjectHome="goProjectHome"></Login>
        <PrivacyPolicy v-model="showPolicyModal"></PrivacyPolicy>
        <TermOfService v-model="showServiceModal"></TermOfService>
    </div>
</template>

<script>
import {getProjectByCode, phoneIsUsed, reqRegisterApplicant, reqSendAuthCode} from "../../api/client-api";
import {checkMobile, checkEmail, encode64} from "../../assets/js/utils";
import Header from "../../components/pc/Header";
import Footer from "../../components/pc/Footer";
import Login from "../../views/pc/login";
import PrivacyPolicy from "../../views/pc/privacyPolicy";
import TermOfService from "../../views/pc/termOfService";

export default {
    components: {Header, Footer, Login, PrivacyPolicy, TermOfService},
    data() {
        return {
            showLoginModal: false,
            showPolicyModal: false,
            showServiceModal: false,
            loading: false,
            projectCode: '',
            headerTitle: '',
            headerBanner: '',
            currentStep: 0,
            disabled: false,
            clickDisabled: false,
            remainingTime: 10,
            registerForm: {
                phone: '',
                authCode: '',
                password: '',
                email: '',
                sign: false,
            },
            registerFormRule: {
                phone: [{required: true, trigger: 'blur',
                    validator: (rule, value, callback) => {
                        this.clickDisabled = false
                        if (!value) {
                            this.clickDisabled = true
                            return callback(new Error('请输入手机号'));
                        }
                        if (!checkMobile(value)) {
                            this.clickDisabled = true
                            return callback(new Error('手机号不正确'));
                        }

                        phoneIsUsed({phone: value}).then((res)=>{
                            if (res.data.data) {
                                this.clickDisabled = res.data.data
                                return callback(new Error('手机号码已注册'));
                            }
                            return callback();
                        }).catch((err)=>{
                            return callback(new Error('手机号码校验失败'));
                        })
                    }
                }],
                authCode: [{required: true, message: '请输入验证码', trigger: 'blur'}],
                password: [
                    {required: true, message: '请输入密码', trigger: 'blur'},
                    {
                        pattern: /^(?![\d]+$)(?![a-zA-Z]+$)(?![!#$%^&*]+$)[\da-zA-Z!#$%^&*]{6,16}$/,
                        message: '密码由字母和数字组成6到16位',
                        trigger: 'blur'
                    },
                ],
                email: [{trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (value && !checkEmail(value)) {
                            return callback(new Error('邮箱不正确'));
                        }
                        return callback();
                    }
                }],
                sign: [{required: true, trigger: 'change',
                    validator: (rule, value, callback) => {
                        if (!value) {
                            return callback(new Error('请检查是否已阅读并同意服务条款、隐私政策'));
                        } else {
                            return callback();
                        }
                    }
                }]
            }
        }
    },
    methods: {
        getProject() {
            getProjectByCode({code: this.projectCode}).then((res)=>{
                if (res.data.data) {
                    let companyName = res.data.data.companyName ? res.data.data.companyName : '';
                    let projectName = res.data.data.name ? res.data.data.name : '';
                    this.headerTitle = companyName + projectName;
                    this.headerBanner = res.data.data.banner;
                }
            }).catch((err)=>{
                this.headerTitle = '';
                this.headerBanner = '';
                console.log('err===', err);
            })
        },
        handleAuthCode() {
            if (this.disabled) {
                return;
            }

            if (!this.registerForm.phone) {
                this.$Message.error('请输入手机号码');
                return;
            }

            reqSendAuthCode({phone: this.registerForm.phone}).then((res)=>{
                this.remainingTime = 0;
                if (res.data.code === 1) {
                    this.remainingTime = res.data.data * 60;
                    this.disabled = true;
                    const run = setInterval(() => {
                        if (this.remainingTime === 0) {
                            clearInterval(run);
                            this.disabled = false;
                        }
                        this.remainingTime--;
                    }, 1000);
                }
            }).catch((err)=>{
                this.remainingTime = 0;
                this.disabled = false;
                console.log('err===', err);
            })
        },
        handleSubmit() {
            let that = this;
            that.$refs['registerForm'].validate((valid) => {
                if (!valid) {
                    return false;
                }

                that.loading = true;
                that.registerForm.password = encode64(encode64(that.registerForm.password) + 'pwd');
                reqRegisterApplicant(that.registerForm).then((res) => {
                    that.loading = false;
                    if (res.data.data === 1) {
                        that.currentStep = 1;
                    } else if (res.data.data === 2 || res.data.data === 4) {
                        that.$Message.error('验证码不正确');
                        that.registerForm.password = ''
                    } else if (res.data.data === 3) {
                        that.$Message.error('用户已存在');
                        that.registerForm.password = ''
                    } else {
                        that.$Message.error('注册失败');
                        that.registerForm.password = ''
                    }
                }).catch((err) => {
                    that.loading = false;
                    that.$Message.error('注册失败');
                    that.registerForm.password = ''
                })
            });
        },
        handleLogin() {
            this.showLoginModal = true;
        },
        goProjectHome() {
            this.$router.push({name: 'project', params: {id: this.projectCode}});
        },
        goTermOfService() {
            this.showServiceModal = true;
        },
        goPrivacyPolicy() {
            this.showPolicyModal = true;
        },
        goHelpCenter() {
            this.$router.push({name: 'helpCenter', query: {projectCode: this.projectCode}});
        },
    },
    mounted() {
        this.projectCode = this.$route.query.projectCode;
        this.getProject()
    }
};
</script>

<style scoped>

</style>
